import styled from "styled-components";

/**
 * Styled component for the UserSettings component
 */
export const StyledUserSettings = styled.div`
  display: flex;
  width: 100vw;
  height: 80vh;
  justify-content: space-evenly;
  flex-flow: row wrap;
  overflow-x: auto;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;

  .back-button {
    text-align: left;
  }

  .outdated {
    color: ${({ theme }) => theme.red};
  }

  .infoIcon {
    height: 1rem;
    width: 1.5rem;
  }

  .payment-method-text-col {
    display: flex;
    align-items: center;

    > p {
      height: min-content;
    }
  }

  .tags-divider {
    border-top: 2px solid ${({ theme }) => theme.menuHover};
  }

  .flex-and-center {
    display: flex;
    justify-content: center;
  }

  .credit-card-wrapper {
    display: flex;
    flex-flow: column wrap;
    margin-bottom: 1.25rem;
  }

  .default-card-button {
    margin-top: 0.5rem;
    border-radius: 14.5px;
    width: fit-content;
    margin-left: 12px;
  }

  .default-card-icon {
    margin-top: 0.2rem;
    margin-left: 12px;
    border-radius: 14.5px;
    display: flex;
    align-items: center;
    width: fit-content;
  }

  .default-card-icon > svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.lightGreen};
  }

  .delete-card-button {
    width: fit-content;
    border-radius: 14.5px;
    border-color: ${({ theme }) => theme.red};
    background-color: ${({ theme }) => theme.red};
    align-self: right;
    color: #3b3c43;
    margin-left: auto;
    margin-right: 12px;
    margin-top: 0.5rem;
  }

  .delete-card-button:active {
    border-color: #5d5d5d;
    background-color: red;
  }

  .delete-card-button:hover {
    border-color: #3d3d3d;
    background-color: #3d3d3d;
  }

  .delete-card-button > svg {
    color: white;
    height: 0.9rem;
    width: 1.1rem;
  }

  .default-card .rccs__card__background {
    background: linear-gradient(25deg, ${({ theme }) => theme.lightGreen}, #4d4d4d);
  }

  .unused-card .rccs__card__background {
    background: linear-gradient(25deg, #60ba89, grey);
  }

  .add-card-button {
    border-radius: 14.5px;
    border-width: 2px;
    border-style: dashed;
    border-color: grey;
    color: ${({ theme }) => theme.lightGreen};
    background: white;
    width: 290px;
    height: 182.873px;
  }

  .add-card-button:hover {
    border-color: ${({ theme }) => theme.lightGreenHover};
  }
`;
