import { tokenRenewingGet } from "../tokenRenewingRequest";
import { Card } from "../../model/Classes/Card";

const baseUrl = "/api/user/cards";

/**
 * Service function responsible for getting your cards
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns response from the backend
 */
export const getCards = async () => {
  return await tokenRenewingGet<Card[]>(baseUrl);
};
