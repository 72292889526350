import axios from "axios";
import StructuredResponse from "../model/Classes/StructuredResponse";
axios.defaults.withCredentials = true;

export const tokenURL = "/api/auth/token";

enum RequestMethod {
  get = "get",
  post = "post",
  put = "put",
  delete = "delete",
}

export const tokenRenewingRequest = async <T>(method: RequestMethod, url: string, data?: object) => {
  const requestConfig = {
    method: method,
    url: url,
    data: data,
    withCredentials: true,
  };

  try {
    const response = await axios.request(requestConfig);
    return new StructuredResponse<T>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.request(requestConfig);
      return new StructuredResponse<T>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<T>(false, e);
    }
  }
};

export const tokenRenewingGet = async <T>(url: string) => {
  return await tokenRenewingRequest<T>(RequestMethod.get, url);
};

export const tokenRenewingPost = async <T>(url: string, data?: any) => {
  return await tokenRenewingRequest<T>(RequestMethod.post, url, data);
};

export const tokenRenewingPut = async <T>(url: string, data?: any) => {
  return await tokenRenewingRequest<T>(RequestMethod.put, url, data);
};

export const tokenRenewingDelete = async <T>(url: string, data?: any) => {
  return await tokenRenewingRequest<T>(RequestMethod.delete, url, data);
};
