import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { InvoiceCard } from "./UserSettingsPaymentMethodComponents";
import { logger } from "../../../../utils/logger";
import { addCard } from "../../../../services/paytrail/addCard";
import { deleteCard } from "../../../../services/paytrail/deleteCard";
import { getCards } from "../../../../services/paytrail/getCards";
import { setDefaultCard } from "../../../../services/paytrail/setDefaultCard";
import User from "../../../../model/Classes/User";
import { Card } from "../../../../model/Classes/Card";
import { Nullable, StateHandler } from "../../../../model/Utilities/Types";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import {
  getInvoicesOutstanding,
  getPaymentsOutstanding,
} from "../../../../services/userSettings/getPaymentsOutstanding";
import { chargeUserInvoices } from "../../../../services/paytrail/chargeUserInvoices";
import { toast } from "react-toastify";
import { useOnTabClicked } from "../../../../hooks";

declare interface UserSettingsPaymentMethodProps {
  user: User;
  successfullAddCard: boolean;
  setSuccessfullAddCard: StateHandler<boolean>;
  setFailedAddCard: StateHandler<boolean>;
  failedAddCard: boolean;
  canceledAddCard: boolean;
  existsAddCard: boolean;
  activeKey: string;
}

const UserSettingsPaymentMethod = ({
  user,
  successfullAddCard,
  setSuccessfullAddCard,
  setFailedAddCard,
  failedAddCard,
  canceledAddCard,
  existsAddCard,
  activeKey,
}: UserSettingsPaymentMethodProps) => {
  // eslint-disable-next-line
  const [cards, setCards] = useState<Nullable<Card[]>>([]);

  const [showView, setShowView] = useState(-1);
  const [handleAddCardFail, setHandleAddCardFail] = useState(false);
  const [handleChangeDefaultCardFail, setHandleChangeDefaultFail] = useState(false);
  const [handleChangeDefaultCardSuccess, setHandleChangeDefaultSuccess] = useState(false);
  const [cardDeletedSuccess, setCardDeletedSuccess] = useState(false);
  const [cardDeletedFail, setCardDeletedFail] = useState(false);
  const [cantRemoveCard, setCantRemoveCard] = useState(false);

  const [runUseEffect, setRunUseEffect] = useState(false);

  const { t } = useTranslation("common", { i18n: i18n });

  useOnTabClicked(
    activeKey,
    ["paymentMethod"],
    () => {
      const getData = async () => {
        const cards = await getCards();
        if (cards.success) {
          setShowView(1);
          setCards(cards.data);
        } else {
          setShowView(0);
        }
      };
      getData();
    },
    [runUseEffect]
  );

  useOnTabClicked(
    activeKey,
    ["paymentMethod"],
    () => {
      const getData = async () => {
        await chargeUserInvoices();
      };
      if (successfullAddCard || handleChangeDefaultCardSuccess) getData();
    },
    [successfullAddCard, handleChangeDefaultCardSuccess]
  );

  const changeDefaultCard = async (card: Card) => {
    if (!card.is_default) {
      const data = {
        card_id: card.id ? card.id : card.payment_method_id,
      };
      toast.dismiss();
      const res = await setDefaultCard(data);
      if (res.success) {
        toast.success(t("global.alert.success.defaultCardChange"));
        setRunUseEffect((r) => !r);
      } else {
        logger(res.data);
        toast.error(t("global.alert.failure.defaultCardChange"));
      }
    }
  };

  const handleDeleteCard = async (card: Card, setShowModal: StateHandler<boolean>) => {
    setShowModal(false);
    toast.dismiss();
    if (cards && (cards.length === 1 || card.is_default)) {
      const has_payments_outstanding = await getPaymentsOutstanding();
      const has_invoices_outstanding = await getInvoicesOutstanding();
      if (Number(has_payments_outstanding.data.total_price) > 0 || has_invoices_outstanding.data.length > 0) {
        toast.warning(t("global.alert.failure.outstanding"));
        return;
      }
    }
    const data = {
      card_id: card.id ? card.id : card.payment_method_id,
    };

    try {
      await deleteCard(data);
      toast.success(t("global.alert.success.cardDelete"));
      setRunUseEffect((r) => !r);
    } catch (e) {
      toast.error(t("global.alert.failure.cardDelete"));
      logger(e);
    }
  };

  if (showView === -1) return <h2 className="align-self-center">{t("global.view.loading")}</h2>;
  if (showView === 0) return <h2 className="align-self-center">{t("global.view.error")}</h2>;

  return (
    <>
      <Row className="mb-3" style={{ justifyContent: "center" }}>
        <InvoiceCard
          cards={cards}
          successfullAddCard={successfullAddCard}
          setSuccessfullAddCard={setSuccessfullAddCard}
          canceledAddCard={canceledAddCard}
          existsAddCard={existsAddCard}
          changeDefaultCard={changeDefaultCard}
          disableChangeDefault={false}
          deleteCard={handleDeleteCard}
          activeKey={activeKey}
          setRunUseEffect={setRunUseEffect}
        />
      </Row>
      <Row className="flex-and-center mt-2">
        <Col xs="auto" sm="auto">
          <p>{t("components.userSettings.tabs.payment.charge")}</p>
        </Col>
      </Row>
      <Row className="flex-and-center mt-2">
        <Col xs="auto" sm="auto">
          <a href="/payment-terms-of-service" target="_blank" rel="noreferrer">
            {t("components.userSettings.tabs.payment.buttons.paymentTos.check")}
          </a>
        </Col>
      </Row>
      <Row className="flex-and-center mt-2">
        <Col xs="auto" sm="auto">
          <div //icky wicky
            dangerouslySetInnerHTML={{
              __html: t("components.userSettings.tabs.payment.service", {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default UserSettingsPaymentMethod;
