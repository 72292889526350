import { tokenRenewingPost } from "../tokenRenewingRequest";

/**
 * Service function responsible for changing your default card
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns response from the backend
 */
export const setDefaultCard = async (data: { card_id?: string | number }) => {
  return await tokenRenewingPost("/api/user/cards/setDefault", data);
};
