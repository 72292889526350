import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { attachPaymentMethod, checkCardValidity } from "../../../../services/stripe/stripeService";
import { StateHandler } from "../../../../model/Utilities/Types";
import { StripeCustomer } from "../../../../model/Classes/StripeCustomer";

import { toast } from "react-toastify";

declare interface StripeCardFormProps {
  clientSecret?: string;
  customer?: StripeCustomer;
  successfullAddCard: boolean;
  setRunUseEffect: StateHandler<boolean>;
  handleModalHide: (confirmed?: boolean) => Promise<void>;
}

export default function StripeCardForm({
  clientSecret,
  customer,
  setRunUseEffect,
  handleModalHide,
}: StripeCardFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation("common", { i18n: i18n });

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const e = await elements.submit();

    if (e.error) {
      toast.error(t("global.alert.failure.cardError"));
      return;
    }

    const { error, confirmationToken } = await stripe.createConfirmationToken({ elements });
    if (error) {
      toast.error(t("global.alert.failure.cardError"));
      return;
    }

    const isValidCard = await checkCardValidity({
      customer_id: customer!.customer_id,
      confirmation_token: confirmationToken.id,
    });

    if (!isValidCard.success) {
      toast.warning(t("global.alert.failure.cardAlready"));
      return;
    }

    const setiRes = await stripe.confirmSetup({
      elements: elements,
      clientSecret: clientSecret,
      redirect: "if_required",
    });

    if (setiRes.error) {
      toast.error(t("global.alert.failure.cardError"));
      handleModalHide(true);
      return;
    }
    const seti = setiRes.setupIntent;

    const pm = seti.payment_method;
    let dpm: string = "";
    if (typeof pm !== "string") {
      if (pm) dpm = pm.id;
    } else {
      dpm = pm;
    }

    const attachRes = await attachPaymentMethod({
      customer_id: customer!.customer_id,
      payment_method_id: dpm,
    });
    if (attachRes.success) {
      toast.success(t("global.alert.success.card"));
      handleModalHide();
      setRunUseEffect((r) => !r);
    } else {
      toast.error(t("global.alert.failure.cardError"));
      handleModalHide();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button type="submit" disabled={!stripe}>
        {t("global.buttons.submit")}
      </Button>
    </form>
  );
}
