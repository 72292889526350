import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import styled from "styled-components";

import i18N from "../../../../i18n";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

import { StateHandler } from "../../../../model/Utilities/Types";
import { TransactionCp, ChargepointMap } from "../../../../model/Classes/Chargepoint";
import { PricingType, AccessType } from "../../../../model/Classes/Area";

import getCurrencySymbol from "../../../../utils/getCurrencySymbol";

import { MaintenanceNotification } from "../../../GeneralComponents/MaintenanceNotification";
import ChargepointDropdown from "../../../Level1/Chargepoint/ChargepointDropdown";
import { ConnectorIdDropdown } from "../../../Level1/Chargepoint/ConnectorIdDropdown";
import { SpotPriceWidget } from "./SpotPriceWidget";

import logo from "../../../../resources/WatteryLogoGreen.svg";

const enum EnumInvoicingMethod {
  test = 0,
  reporting = 1,
  invoice = 2,
}

export const StartNewTransactionView = ({
  selectedChargepoint,
  setSelectedChargepoint,
  setSelectedConnector,
  publicChargepoints,
  myChargepoints,
  recentChargepoints,
  handleSubmit,
  connectorId,
  mustSetConnectorId,
  hasInterrupts,
}: {
  selectedChargepoint?: TransactionCp;
  setSelectedChargepoint: Function;
  setSelectedConnector: Function;
  publicChargepoints: ChargepointMap;
  myChargepoints: ChargepointMap;
  recentChargepoints: ChargepointMap;
  handleSubmit: () => Promise<void>;
  connectorId: number | null;
  setConnectorId: StateHandler<number | null>;
  mustSetConnectorId: boolean;
  hasInterrupts: boolean;
}) => {
  const { t } = useTranslation("common", {
    i18n: i18N,
  });

  // Collect price information in array where members are later combined
  // to a single string with a plus sign (+) as separator.
  let chargePrice: Array<string | Number> = [];

  const currencySymbol = getCurrencySymbol(selectedChargepoint?.currency);

  if (!!!selectedChargepoint) {
    // Do nothing, just for clarity with this structure
  } else if (selectedChargepoint?.free_charging === 1) {
    chargePrice.push(t("components.startTransaction.view.freeOfCharge"));
  } else if (selectedChargepoint.pricing_type === PricingType.fixed) {
    switch (selectedChargepoint.access_type) {
      case AccessType.free:
        chargePrice.push(Number(0).toFixed(2) + ` ${currencySymbol}`);
        break;
      case AccessType.private:
        chargePrice.push(Number(selectedChargepoint.price).toFixed(2) + ` ${currencySymbol}/kWh`);
        break;
      case AccessType.privateAndPublic:
        const hasPrivateAccess = myChargepoints.has(String(selectedChargepoint.charge_point_id));
        if (hasPrivateAccess) {
          chargePrice.push(Number(selectedChargepoint.price).toFixed(2) + ` ${currencySymbol}/kWh`);
        } else {
          if (Number(selectedChargepoint.public_start_price) > 0) {
            chargePrice.push(Number(selectedChargepoint.public_start_price).toFixed(2) + ` ${currencySymbol}/kWh`);
          }
          chargePrice.push(Number(selectedChargepoint.public_price).toFixed(2) + ` ${currencySymbol}/kWh`);
        }
        break;
      case AccessType.public:
        if (Number(selectedChargepoint.public_start_price) > 0) {
          chargePrice.push(Number(selectedChargepoint.public_start_price).toFixed(2) + ` ${currencySymbol}/kWh`);
        }
        chargePrice.push(Number(selectedChargepoint.public_price).toFixed(2) + ` ${currencySymbol}/kWh`);
        break;
    }
  } else if (selectedChargepoint.pricing_type === PricingType.spot) {
    if (Number(selectedChargepoint.margin) !== 0) {
      chargePrice.push(Number(selectedChargepoint.margin).toFixed(3) + ` ${currencySymbol}/kWh`); // NB! Three decimals!
    }

    const invMethod = selectedChargepoint.invoicing_method;
    if (invMethod === EnumInvoicingMethod.invoice) {
      chargePrice.push(t("components.startTransaction.view.spotPriceMin")); // Minimum 0 €
    } else if (invMethod === EnumInvoicingMethod.test || invMethod === EnumInvoicingMethod.reporting) {
      chargePrice.push(t("components.startTransaction.view.spotPrice"));
    }
  }

  return (
    <Container>
      <Row style={{ justifyContent: "center" }}>
        <Image className="logo" src={logo} alt="Wattery logo" />
        <MaintenanceNotification />
      </Row>

      <Row className="mb-3">
        <Form>
          <Row>
            <Col style={{ textAlign: "left" }}>
              {!!!selectedChargepoint && (
                <DropdownLabel>{t("components.startTransaction.static.header")}</DropdownLabel>
              )}
              <ChargepointDropdown
                selectedChargepoint={selectedChargepoint}
                onSelectChargepoint={setSelectedChargepoint}
                publicChargepoints={publicChargepoints}
                myChargepoints={myChargepoints}
                recentChargepoints={recentChargepoints}
              />
            </Col>
          </Row>

          {!!selectedChargepoint && (
            <>
              {mustSetConnectorId && (
                <Row className="mt-1 mb-1">
                  <ConnectorIdDropdown
                    numberOfConnectors={selectedChargepoint.number_of_connectors}
                    connectorId={connectorId}
                    onSelectConnector={setSelectedConnector}
                  />
                </Row>
              )}
              {selectedChargepoint.address && (
                <Row className="mt-1">
                  <Col style={{ textAlign: "left" }}>
                    <FontAwesomeIcon icon={faGlobe} /> {selectedChargepoint.address}
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <StartTransactionContainer>
                    <div>
                      <div>
                        {selectedChargepoint.ownerName &&
                          t("components.startTransaction.view.owner", {
                            owner: selectedChargepoint.ownerName,
                            interpolation: { escapeValue: false }, // Otherwise escapes slashes etc.
                          })}
                      </div>
                      <div className="price">
                        {chargePrice.join(" + ")}
                        <span className="subtle">*</span>
                      </div>
                    </div>
                    <div>
                      {hasInterrupts || (mustSetConnectorId && !connectorId) ? (
                        <StyledStartButton className="disabled" disabled={true}>
                          {t("components.startTransaction.buttons.start.text")}
                        </StyledStartButton>
                      ) : (
                        <StyledStartButton onClick={handleSubmit}>
                          {t("components.startTransaction.buttons.start.text")}
                        </StyledStartButton>
                      )}
                    </div>
                  </StartTransactionContainer>
                </Col>
              </Row>

              <Row className="mt-4">
                {selectedChargepoint.pricing_type !== 0 && (
                  <SpotPriceWidget chargepointId={selectedChargepoint.charge_point_id} />
                )}
              </Row>

              <Row className="mt-4">
                <Col>
                  <div className="subtle">
                    *
                    {t("components.startTransaction.view.footer", {
                      currency: getCurrencySymbol(selectedChargepoint?.currency),
                    })}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Row>
    </Container>
  );
};

const DropdownLabel = styled.span`
  display: block;
  text-align: left;
  font-size: 90%;
`;

const StartTransactionContainer = styled.div`
  text-align: center;
  margin-bottom: 2rem;

  & > div {
    margin-top: 2rem;

    div.price {
      font-size: 110%;
      font-weight: bold;
    }
  }
`;

const StyledStartButton = styled(Button)`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  font-size: 150%;
  line-height: 1.1;
  box-shadow: 0px 0px 6px -2px #777, inset 0px 0px 8px #fff;
  border-width: 0.5px;

  &:active {
    box-shadow: none !important;
    background-color: ${({ theme }) => theme.lightGreen} !important;
    border-color: transparent !important;
    padding: 5px 13px;
  }

  &.disabled {
    background: #888;
    border-color: #888;
    color: #ffffffaa;
  }
`;
