export const DarkTheme = {
  body: "#0D0C1D",
  text: "#FAFAFA",
  toggleBorder: "#6B8096",
  background: "#0D0C1D",
  menuBackground: "#383D3D",
  notification: "#00cc00",
  error: "#FF0000",
  mobile: "576px",
  hover: "#3175bd",
} as const;

export const LightTheme = {
  body: "#FFF",
  text: "#3B3C43",
  toggleBorder: "#FAFAFA",
  background: "#FFF",
  notification: "#00cc00",
  error: "#FF0000",
  mobile: "576px",

  lightGray: "#E8E9E8",
  darkGray: "#3B3C43",
  cpHealth0Gray: "#B9BDB9",
  lightGreen: "#00B451",
  lightGreenHover: "#00813A",
  mediumGreen: "#007D5C",
  darkGreen: "#005740",
  red: "#DC3545",
  yellow: "#FFED00",
  darkYellow: "#c7b900",

  menuHover: "#CDCDCD",
  menuText: "#FFFFFF",

  btnPrimaryHover: "#00A552",
  btnPrimaryFocusBoxShadow: "0 0 0 0.25rem rgb(0 151 75 / 50%) !important",

  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  fourK: "2560px",
} as const;
